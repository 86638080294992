/* globals Sentry, define */
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import 'intersection-observer'
import requirejs from 'requirejs'
import {getRjsConfig} from './rjs-config'
import * as sharedRegistry from './init/sharedRegistry'
import {initBeatEvents} from './bi/initBeatEvents'
import {BEATS} from './bi/constants'
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils.js'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery'
import isBotFn from 'santa-main-r/lib/lib/common/isBot'
import createLogger from './init/logger'
import createWorkerFactory from './init/platform/createWorker'

window.santaBase = `${window.boltBase}/node_modules/wix-santa`
window.wixBiSession = window.wixBiSession || {}
const queryUtil = getQueryUtils(window)
window.queryUtil = queryUtil

const {rendererModel, publicModel, location, navigator, isStreaming, serviceTopology, wixBiSession, boltBase, santaBase} = window
rendererModel.runningExperiments = addExperimentsFromQuery(rendererModel.runningExperiments, queryUtil, 'viewer')
const requestModel = {
    userAgent: navigator.userAgent,
    cookie: document.cookie,
    deviceType: publicModel.deviceInfo ? publicModel.deviceInfo.deviceType : 'desktop'
}
const rawUrl = location.href
const isInSSR = false
const isDebug = Boolean(getQueryUtils(window).getParameterByName('debug'))

const logger = createLogger(Sentry, {rendererModel, publicModel, requestModel, rawUrl, boltBase, isInSSR})

const experimentInst = experimentFactory.build(window)
const isBot = isBotFn(window)
const dummyFunc = () => 1

window.performance = window.performance || {mark: dummyFunc, now: dummyFunc}
window.performance.mark = window.performance.mark || dummyFunc
window.performance.now = window.performance.now || dummyFunc

const rjsConfig = getRjsConfig(boltBase, santaBase, serviceTopology, false, location.search)
requirejs.config(rjsConfig)

const noop = () => {
}
define('mobx', [], () => ({
    runInAction: noop,
    action: noop,
    isObservableArray: noop
}))
define('mobx-react', [], () => ({
    observer: noop
}))

if (experimentInst.isOpen('bv_earlyRequireBoltMain')) {
    requirejs(['bolt-main'], () => {
        console.log('bolt-main loaded', performance.now())
    })
}

requirejs(['lodash'], _ => {
    const initPromise = import('./init')
    const boltAnimationsPromise = import('bolt-animations/src/warmupAnimations')

    sharedRegistry.init()

    const reportBeatEvent = initBeatEvents(experimentInst, isBot)
    const {beatNumber, eventName} = BEATS.MAIN_R_LOADED
    reportBeatEvent(beatNumber, eventName)

    initPromise.then(({init, createFunctionLibrary}) => {
        const fetchFunction = (url, options, dataType) => fetch(url, options || undefined)
            .then(res => {
                if (!res.ok && !_.get(options, 'allowErrors')) {
                    throw new Error(`Fetch failed. Status: ${res.status}. Reason: ${res.statusText}.`)
                }

                return res[dataType || 'json']()
            })

        const ssrModel = {
            isStreaming,
            isInSSR,
            boltInstanceFunctionLibrary: {
                failSsrRequest: e => {
                    throw e
                }
            }
        }

        const hostInstanceBatchingStrategy = function () { // eslint-disable-line func-style
            setImmediate(() => {
                this.$endBatch()
            })
        }

        const boltInstanceBatchingStrategy = null
        const functionLibrary = createFunctionLibrary({
            fetchFunction,
            requireFunction: requirejs,
            workerFunction: createWorkerFactory(isDebug),
            biReporter: null,
            boltAnimationsPromise,
            logger
        })

        return init({
            logger,
            ssrModel,
            hostInstanceBatchingStrategy,
            boltInstanceBatchingStrategy,
            functionLibrary,
            rendererModel,
            rawSeoTags: undefined,
            publicModel,
            serviceTopology,
            requestModel,
            rawUrl,
            wixBiSession,
            reportBeatEvent,
            renderFlags: window.renderFlags || {},
            isBot,
            isDebug,
            santaBase
        })
    }).then(({doneStagePromise}) => doneStagePromise).then(async result => {
        const {hostInstance, boltInstance, boltMain, hydrate, serverMarkup, indicator} = result
        const rootElement = document.getElementById('SITE_CONTAINER')
        await boltMain.renderClientSide(boltInstance, rootElement, hydrate, serverMarkup, indicator, logger)
        hostInstance.setRenderPhase(hydrate ? 'hydrate' : 'render')
    }).then(() => { //eslint-disable-line consistent-return
        if (getQueryUtils(window).getParameterByName('ds')) {
            //TODO debug only
            const dsPromise = import('bolt-ds-adapter')
            return dsPromise.then(({createAdapter, createDocumentServices}) => { //eslint-disable-line promise/no-nesting
                const adapter = createAdapter(window.boltInstance)
                window.documentServices = createDocumentServices(adapter)
            })
        }
    })
})

